<i18n lang="json">
{
  "en": {
    "search": {
      "desktop": "Search",
      "mobile": "Find RVs for sale"
    },
    "learnMore": "Learn more",
    "averageEarnings": "You can earn $1,500 per month on average renting out your RV."
  },
  "fr": {
    "search": {
      "desktop": "Recherche",
      "mobile": "Trouvez des VR à vendre"
    },
    "learnMore": "En savoir plus",
    "averageEarnings": "Vous pouvez gagner en moyenne 1 500 $ par mois en louant votre VR."
  }
}
</i18n>

<template>
  <div class="w-dvw flex flex-col lg:w-[32.5rem]">
    <SearchDesktopForm
      v-if="isLargeScreen"
      :button-text="t('search.desktop')"
      class="from-homepage rounded-[4rem] bg-white"
    />
    <div v-else class="px-4 w-full">
      <SectionCard class="border border-primary-100">
        <SearchMobileForm :show-drawer="false" :button-text="t('search.mobile')" class="mb-4" />
        <div class="typography-caption text-primary text-center">
          {{ t('averageEarnings') }}
          <NuxtLink :href="`${appConfig.rentals.webUrl}/owner`" class="typography-caption-link">{{
            t('learnMore')
          }}</NuxtLink>
        </div>
      </SectionCard>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n({ useScope: 'local' })
const isLargeScreen = useIsLargeScreen()
const appConfig = useRuntimeConfig().public.app
</script>
